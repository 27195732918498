import React, { useEffect, useState } from 'react'
import IconAngleLeft from 'atoms/SvgIcons/IconAngleLeft.svg';
import IconAngleRight from 'atoms/SvgIcons/IconAngleRight.svg';
import Carousel from 'atoms/Carousel';
import Heading from 'atoms/Heading';
import Button from 'atoms/Button';
import IconArrowPrevious from 'atoms/SvgIcons/IconArrowPrevious.svg';
import IconArrowNext from 'atoms/SvgIcons/IconArrowNext.svg';
                        
const HorizontalSliderV2 = ({ sliderList, spaceBetween = 12, navigationElement, breakpoints, handleViewAll, heading, className = '', dataAutomation, isShowArrow = true, isAutoPlay=false, autoplayDelay=null, reseller=false, isShowPagination = false }) => {

    return (
        <div className={`${className} relative px-2 md:px-0`}>
            <div className='flex md:mx-0 justify-between items-center md:mb-1'>
                <Heading className="text-18-28 md:text-3xl lg:text-3.1xl text-basic-blackPure text-center w-full font-semibold" type='H2'>{heading}</Heading>
                {handleViewAll && <Button variant='Link' size='medium' className='text-primary1-500 flex' onClick={handleViewAll} dataAutomation={dataAutomation}>
                    View all
                </Button>}
            </div>
            {isShowArrow &&
                <div className='w-full hidden md:block'>
                    <button className={`${navigationElement}Arrow--prev absolute -left-5.5 lg:-left-8 top-[calc(35%_+_0px)] rounded-full  z-10 group`} data-automation='brands-left-arrow'>
                        <IconArrowPrevious className='w-10 text-gray-700' />
                    </button>
                    <button className={`${navigationElement}Arrow--next absolute -right-5.5 lg:-right-8 top-[calc(35%_+_0px)] rounded-full z-10 group`} data-automation='brands-right-arrow'>
                        <IconArrowNext className='w-10 !text-gray-700' />
                    </button>
                </div>
            }
            <div>
                <Carousel
                    breakpoints={breakpoints}
                    spaceBetween={spaceBetween}
                    isShowArrow
                    navigationElement={navigationElement}
                    className={`!px-0 ${reseller ? '!py-0 !pb-4 md:!pb-0' : '!py-2'} sm:!py-4`}
                    sliderList={sliderList}
                    isAutoPlay={isAutoPlay}
                    autoplayDelay={autoplayDelay}
                    isShowPagination={isShowPagination}
                />
            </div>
        </div>
    )
}
export default HorizontalSliderV2